.download-container{
  height: 100%;
  align-items: center;
}
.download-tune{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 650px;
  height: 400px; 
  box-sizing: border-box;
}
.download-form{
  margin: 5px;
  align-items: center;
}
.download-label{
  color:#000;
  font-family: Arial, Helvetica, sans-serif;
}
.download-input{
  width: 300px;
  height: 30px;
  border: 1px solid #000;
  padding: 5px;
  margin: 5px;
  font-family: Arial, Helvetica, sans-serif;
}
.input-item{
  width: 300px;
  height: 30px;
  border: 1px solid #000;
  padding: 5px;
  margin: 5px;
  font-family: Arial, Helvetica, sans-serif;
}
.download-submit{
  width: 150px;
  height: 30px;
  border: 1px solid #000;
  padding: 5px;
  margin: 5px;
  background: #009E60;
  color:#fff;
  font-size: 1rem;
}
.download-submit:hover{
  background: #fff;
  color: #009E60;
  transition: 250ms;
  transition: all 0.3s ease-out;
}
.details-row{
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.details-column{
  padding: 10px;
  margin: 5px;
}
.details-column p{
  padding: 5px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}
.audio{
  margin-left: 400px;
}

.box{
  border: 2px solid grey;
  width: 500px;
  height: 520px;
  margin: auto;
  margin-top: 50px;
}
.tabs{
  display: flex;
  justify-content: space-between;
}
.tab{
  background: #ccc;
  flex: 1;
  padding: 10px;
  text-align: center;
  border: 1px solid grey;
  border-top: none;
  cursor: pointer;
  position: relative;
}
.tab:hover{
  color:#009E60;
  transition: all 0.2s ease-out;
}
.active-tab{
  border: none;
  background: #fff;
}
.active-tab::after{
  border-top: 2px solid #009E60;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: initial;
  animation: new 0.5s;
}
@keyframes new{
  from{
    top:50px;
    left: 0;
    right: 0;
    background: grey;
  }
  to{
    top:0px;
    left: 0;
    right: 0;
    border-top: 2px solid #009E60;   
  }
}
.contents{
  position: relative;
}
.content{
  display: none;
  position: relative;
}
.active-content{
  display: block;
  margin-left: 10px;
  margin-right: 10px;
}
.otp-area{
  width:70%;
  margin: 20px auto;
  display:flex;gap: 10px;

  input{
    width: 50px;
    padding: 10px;
    outline:none;
    text-align: center;
    border: 1px solid #000;

    &:focus{
      border: 2px solid #009E60;
    }
  }
}
@media screen and (max-width: 960px){
  .box{
      width: 400px;
      height: 500px;
  }
  .download-tune{
    margin-left: 0;
  }
  .download-form{
    margin-right: 220px;
  }
  .download-input{
    width: 200px;
  }
  .details-row{
    margin-right: 220px;
  }
}
@media screen and (max-width: 768px) {
}