.footer-container {
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
}
.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons{
  margin-top: 50px;
  width: 280px;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 620px;
}
.social-icons p{
  color: green;
  font-size: 20px;
  margin-right: 130px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}
.social-icons ul{
  color: green;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
  'Lucida Sans', Arial, sans-serif;
}
.mail:hover{
  text-decoration: underline;
}
@media screen and (max-width: 820px) {
  .footer-container{
    align-items: center;
    height: 100%;
  }
  .social-media{
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .social-icons{
    margin: 0;
    margin-top: 20px;
    margin-left: 50px;
  }
  .social-icons p{
    font-size: 1.2rem;
  }

}

@media screen and (max-width: 768px) {
}